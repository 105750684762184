'use client'

import { I18nDictionary, useI18n } from '@/components/i18n/lang-context'
import { Footer } from '@/components/jbda/footer'
import { Header } from '@/components/jbda/header'
import { Flex, FlexCol } from '@/components/ui/flex'
import { Page } from '@/components/ui/page'
import { setPreciseInterval } from '@/lib/utils/utils'
import Link from 'next/link'
import { redirect, usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

const I18N: I18nDictionary = {
  title: { en: '404 Error - Page Not Found', fr: 'Erreur 404 - Page Introuvable' },
  desc: { en: 'The page you are trying to reach ({1}) does not exist.', fr: 'La page que vous recherchez ({1}) n\'existe pas.' },
  redirect: { en: 'Redirecting to the', fr: 'Redirection vers la' },
  homePage: { en: 'Home Page', fr: 'Page d\'Accueil' },
  in: { en: 'in', fr: 'dans' },
  seconds: { en: 'seconds', fr: 'secondes' }
}

export default function NotFound({ params }: { params: { lang: string } }) {

  const i18n = useI18n(I18N)
  const pathName = usePathname()
  const [counter, setCounter] = useState(9)
  let mounted = true

  useEffect(() => {
    if (!mounted) return
    const interval = setPreciseInterval(() => setCounter(c => {
      if (c > 0) return c - 1
      else {
        interval.stop()
        redirect('/')
        return 0
      }
    }), 1000)
    return () => { mounted = false }
  }, [])

  return (
    <Page className='flex h-[100vh] flex-col'>
      <Header langCode={params.lang} />
      <Flex className='grow items-center justify-center'>
        <FlexCol className='items-center justify-center gap-8 rounded-xl bg-primary p-16'>
          <div id='TITLE' className='opacity=80 text-2xl font-light text-white'>{i18n.title}</div>
          <div id='DESC' className='text-3xl text-white'>{i18n.desc.replace('{1}', pathName)}</div>
          <div id='REDIRECT' className='text-base font-light text-white'>{i18n.redirect} <Link href='/' className='font-bold text-blue-300 hover:underline'>{i18n.homePage}</Link> {i18n.in} <span className='inline-block w-3 text-center font-bold'>{counter}</span> {i18n.seconds}...</div>
        </FlexCol>
      </Flex>
      <Footer />
    </Page>
  )
}
